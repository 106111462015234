//import logo from './logo.svg';
//import './App.css';
import Todo from "./components/todo";

function App() {
  return (
    <div >
     
      <Todo/>
    </div>
  );
}

export default App;
